import { useContext, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { Modal, Form, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { BiblicalTermMarker } from '../../models3/BiblicalTermMarker'
import { Passage } from '../../models3/Passage'
import { ProjectTerm } from '../../models3/ProjectTerm'
import { getTermSenseName } from '../../resources/Lemmas'
import { RootContext } from '../app/RootContext'
import {
    CancelButton,
    FAButton,
    HelpLinkWithMessage,
    NextSegmentButton,
    OKButton,
    PreviousSegmentButton,
    TrashButton
} from '../utils/Buttons'

import './Gloss.css'
import '../modals/Modals.css'

interface BiblicalTermMarkerEditorProps {
    closeViewer: () => void
    terms: ProjectTerm[]
    termId: string
    biblicalTermMarker: BiblicalTermMarker
    markers: BiblicalTermMarker[]
    isNewMarker?: boolean
    passage: Passage
    allowEditing: boolean
    goToPreviousMarker: () => void
    goToNextMarker: () => void
}

export const BiblicalTermMarkerEditor = observer(
    ({
        biblicalTermMarker,
        markers,
        closeViewer,
        terms,
        termId,
        isNewMarker,
        passage,
        allowEditing,
        goToPreviousMarker,
        goToNextMarker
    }: BiblicalTermMarkerEditorProps) => {
        const { t, i18n } = useTranslation()
        const dir = i18n.dir()
        const rt = useContext(RootContext)

        const [openTermId, setOpenTermId] = useState(termId)
        const [selectedTermId, setSelectedTermId] = useState(termId)
        const [targetGlossId, setTargetGlossId] = useState(biblicalTermMarker.targetGlossId)
        const [creatingNewGloss, setCreatingNewGloss] = useState(false)
        const [newGlossText, setNewGlossText] = useState('')
        const [newGlossTextError, setNewGlossTextError] = useState('')

        useEffect(() => {
            setOpenTermId(termId)
            setSelectedTermId(termId)
            setTargetGlossId(biblicalTermMarker.targetGlossId)
            setCreatingNewGloss(false)
            setNewGlossText('')
            setNewGlossTextError('')
        }, [biblicalTermMarker, termId])

        const save = async () => {
            let glossId = creatingNewGloss ? '' : targetGlossId
            if (creatingNewGloss && selectedTermId && newGlossText && !newGlossTextError) {
                const currentTerm = terms.find((te) => te._id === selectedTermId)
                if (currentTerm) {
                    const gloss = await currentTerm.addGloss(newGlossText)
                    glossId = gloss?._id ?? ''
                }
            }

            if (glossId) {
                if (isNewMarker) {
                    biblicalTermMarker.targetGlossId = glossId
                    const video = passage.findVideo(biblicalTermMarker._id)
                    if (video) {
                        await video.addMarkerFromExisting(biblicalTermMarker)
                    }
                } else {
                    await biblicalTermMarker.setTargetGlossId(glossId)
                }
                rt?.project.cleanUpTargetGlosses()
            }
        }

        const okButtonEnabled = Boolean(
            (selectedTermId && openTermId && selectedTermId === openTermId && targetGlossId) ||
                (creatingNewGloss && newGlossText.trim() && !newGlossTextError)
        )

        const i = markers.findIndex((marker) => marker._id === biblicalTermMarker._id)

        const editNewTargetGloss = (term: ProjectTerm) => {
            setCreatingNewGloss(true)
            setSelectedTermId(term._id)
            setTargetGlossId('')
        }

        return (
            <Modal onHide={closeViewer} show backdrop="static" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="biblical-term-marker-editor-header">
                            {t('biblicalTermMarkerHeader')}
                            {i >= 0 && (
                                <div className="note-navigator">
                                    <PreviousSegmentButton
                                        onClick={async () => {
                                            if (allowEditing && okButtonEnabled) {
                                                await save()
                                            }
                                            goToPreviousMarker()
                                        }}
                                        tooltip={t('goToPreviousMarker')}
                                        enabled={i > 0}
                                        dir={dir}
                                    />
                                    {t('{{x}} of {{y}}', { x: i + 1, y: markers.length })}
                                    <NextSegmentButton
                                        onClick={async () => {
                                            if (allowEditing && okButtonEnabled) {
                                                await save()
                                            }
                                            goToNextMarker()
                                        }}
                                        tooltip={t('goToNextMarker')}
                                        enabled={i < markers.length - 1}
                                        dir={dir}
                                    />
                                </div>
                            )}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {terms.length === 0 && (
                        <HelpLinkWithMessage message={t('noPassageBiblicalTerms')} id="biblical-terms" />
                    )}
                    {terms.length > 0 && (
                        <>
                            {allowEditing && <p className="key-term-marker-help">{t('biblicalTermMarkerHelp')}</p>}
                            <Table>
                                <thead>
                                    <tr>
                                        <th>{t('biblicalTermHeader')}</th>
                                        <th>{t('sourceGlossesHeader')}</th>
                                        <th>{t('targetGlossesHeader')}</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {terms.flatMap((term) => {
                                        const lemma = term.getMarbleLemma()
                                        if (lemma) {
                                            const lexMeaning = lemma.meanings.find(
                                                (meaning) => meaning.lexicalLink === term.lexicalLink
                                            )
                                            let termString = ''
                                            let sourceGlosses = ''
                                            if (lexMeaning) {
                                                termString = getTermSenseName(lemma, lexMeaning)
                                                sourceGlosses = lexMeaning.glosses(i18n.language)
                                            }

                                            const validateName = (text: string) => {
                                                if (term.glosses.find((gloss) => gloss.text === text.trim())) {
                                                    return t('Duplicate name')
                                                }

                                                if (!text.trim()) {
                                                    return t('Empty name')
                                                }

                                                return ''
                                            }

                                            return (
                                                <tr
                                                    key={term._id}
                                                    onClick={() => {
                                                        if (term._id !== openTermId) {
                                                            setCreatingNewGloss(false)
                                                            setNewGlossText('')
                                                            setNewGlossTextError('')
                                                            setOpenTermId(term._id)
                                                        }
                                                    }}
                                                >
                                                    <td>{termString}</td>
                                                    <td>{sourceGlosses}</td>
                                                    <td>
                                                        {term._id !== openTermId &&
                                                            term.glosses.map((gloss) => gloss.text).join('; ')}
                                                        {term._id === openTermId && (
                                                            <>
                                                                {term.glosses.map((gl) => (
                                                                    <Form.Check
                                                                        type="radio"
                                                                        id={gl._id}
                                                                        key={gl._id}
                                                                        checked={gl._id === targetGlossId}
                                                                        disabled={!allowEditing}
                                                                        onChange={() => {
                                                                            setCreatingNewGloss(false)
                                                                            setSelectedTermId(term._id)
                                                                            setTargetGlossId(gl._id)
                                                                        }}
                                                                        label={gl.text}
                                                                    />
                                                                ))}
                                                                <Form.Check
                                                                    type="radio"
                                                                    id="new-gloss"
                                                                    checked={creatingNewGloss}
                                                                    disabled={!allowEditing}
                                                                    onChange={() => editNewTargetGloss(term)}
                                                                    label={
                                                                        <div>
                                                                            <input
                                                                                disabled={!allowEditing}
                                                                                value={newGlossText}
                                                                                onChange={(e) => {
                                                                                    const text = e.target.value
                                                                                    editNewTargetGloss(term)
                                                                                    setNewGlossText(text)
                                                                                    setNewGlossTextError(
                                                                                        validateName(text)
                                                                                    )
                                                                                }}
                                                                            />
                                                                            {newGlossTextError && (
                                                                                <div className="new-term-marker-error">
                                                                                    {newGlossTextError}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <FAButton
                                                            enabled
                                                            className={
                                                                term._id === openTermId
                                                                    ? 'fa-chevron-up key-term-marker-arrow-button'
                                                                    : 'fa-chevron-down key-term-marker-arrow-button'
                                                            }
                                                            onClick={() => {}}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        return null
                                    })}
                                </tbody>
                            </Table>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex-centered button-row biblical-term-marker-editor-footer">
                        <div className="modal-footer-buttons">
                            {(!allowEditing || terms.length === 0) && (
                                <OKButton enabled onClick={closeViewer} className="ok-button" tooltip={t('Close')} />
                            )}
                            {allowEditing && terms.length > 0 && (
                                <OKButton
                                    enabled={okButtonEnabled}
                                    onClick={async () => {
                                        await save()
                                        closeViewer()
                                    }}
                                    className="ok-button"
                                    tooltip={t('Save changes')}
                                />
                            )}
                            {allowEditing && (
                                <CancelButton
                                    enabled
                                    onClick={closeViewer}
                                    className="cancel-button"
                                    tooltip={t('Cancel')}
                                />
                            )}
                        </div>
                        {allowEditing && !isNewMarker && (
                            <TrashButton
                                className="key-term-marker-delete-button"
                                onClick={async () => {
                                    const video = passage.findVideo(biblicalTermMarker._id)
                                    if (video) {
                                        await video.removeBiblicalTermMarker(biblicalTermMarker._id)
                                        rt?.project.cleanUpTargetGlosses()
                                    }
                                    closeViewer()
                                }}
                                tooltip={t('deleteBiblicalTermMarker')}
                                enabled
                            />
                        )}
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
)
