const HelpDocUrlMap = new Map<string, string | undefined>([
    ['ar', process.env.REACT_APP_HELP_DOC_AR_URL],
    ['bi', process.env.REACT_APP_HELP_DOC_BI_URL || process.env.REACT_APP_HELP_DOC_EN_URL],
    ['en', process.env.REACT_APP_HELP_DOC_EN_URL],
    ['es', process.env.REACT_APP_HELP_DOC_ES_URL],
    ['fr', process.env.REACT_APP_HELP_DOC_FR_URL],
    ['ha', process.env.REACT_APP_HELP_DOC_HA_URL],
    ['pt', process.env.REACT_APP_HELP_DOC_PT_URL],
    ['ru', process.env.REACT_APP_HELP_DOC_RU_URL],
    ['sw', process.env.REACT_APP_HELP_DOC_SW_URL],
    ['tpi', process.env.REACT_APP_HELP_DOC_TPI_URL],
    ['zh-CN', process.env.REACT_APP_HELP_DOC_ZH_CN_URL]
])

const HelpIdMap = new Map<string, string>([
    ['backup-restore', 'c3dli3sxr43o'],
    ['bibles', 'ynexgash1jkh'],
    ['biblical-terms', 'e6phb6xhc8lg'],
    ['biblical-terms-insert', 'riu2ow9va4vo'],
    ['biblical-terms-passage', 'tad4hhdwd3n'],
    ['compressor', '6yizuwfj3ekc'],
    ['images', 'lvdj9b8o6agv'],
    ['noPassageBiblicalTerms', 'tad4hhdwd3n'],
    ['notes', 'x5c4fnl8zavj'],
    ['notes-insert', '6ynn9emtms50'],
    ['passages-export', '519lmwnfolm4'],
    ['passages-info', '7a2bmk7h4pu5'],
    ['passages-record', 'mz8kizd7lesb'],
    ['passages-resources', 'jtockmnfvrck'],
    ['patches', 'hqg7fm7970wg'],
    ['permission-denied-while-trying-to-record', '62ddld38r94x'],
    ['portions-export', 'td8ubaecsn11'],
    ['portions', '7ryizhctdwvk'],
    ['segments-create', 'vjau3swfvbaj'],
    ['settings', 'oabj7u7s1dnv'],
    ['sign-out-info', 'ktggnawqubw6'],
    ['status', 'n3pzy6z42xpl'],
    ['verses-add', '1szvmqkr1qf9'],
    ['verses', '1bh6su1qof28']
])

export enum LocalStorageKeys {
    RECORDING_PANE_WIDTH = 'recordingPaneWidth',
    RECORDING_PANE_HEIGHT = 'recordingPaneHeight',
    RESOURCES_PANE_SPLIT = 'resourcesPaneSplit',
    PASSAGE_PANE_WIDTH = 'passagePaneWidth',
    VIDEO_MAIN_TOP_HEIGHT = 'videoMainTopHeight',
    PROJECT_RESTORE_IN_PROGRESS = 'projectRestoreInProgress',
    PAGE_SELECTED = 'pageSelected',
    INTEST = 'intest',
    TEST_PUBLISH_MESSAGE = 'testPublishMessage',
    LAST_SUCCESSFUL_BACKUP = 'lastSuccessfulBackup',
    DEBUG_DOWNLOADS = 'debugDownloads',
    VIDEO_PLAYBACK_RATE = 'videoPlaybackRate',
    EAF_CREATOR = 'eafcreator',
    PROJECTS = 'projects',
    SLTT_AUTH_TYPE = 'sltt_auth_type',
    SLTT_USERNAME = 'sltt_username',
    SLTT_ID_TOKEN = 'sltt_id_token',
    SLTT_CURRENT_PROJECT_NAME = 'sltt_currentProjectName',
    SCREEN_CAPTURE = 'screencapture',
    DISABLE_IMPLICIT = 'disableImplicit',
    CACHE_RESET_REQUEST = 'cacheResetRequest',
    VIDEO_CACHE_LIMIT_GB = 'videoCacheLimitGB',
    LAST_SUCCESSFUL_SYNC = 'lastSuccessfulSync',
    UNSYNCED_DATA_EXISTS_TAG = '-unsynced-data-exists',
    LAST_SUCCESSFUL_SYNC_TAG = '-last-successful-sync',
    VISIBLE_PUBLISHED_BIBLES = 'publishedBiblesVisible',
    PUBLISHED_BIBLE_SETTINGS = 'publishedBibleSettings',
    REVIEW_PROJECT_TAB_INDEX = 'reviewProjectTabIndex',
    REVIEW_PROJECT_CURRENT_REVIEW = 'reviewProjectCurrentReview',
    ACCORDION_LEFT_IS_OPEN = 'accordionLeftIsOpen',
    ACCORDION_MIDDLE_IS_OPEN = 'accordionMiddleIsOpen',
    ACCORDION_RIGHT_IS_OPEN = 'accordionRightIsOpen',
    SHOULD_ADD_VERSE_REFERENCES = 'shouldAddVerseReferences'
}

export const avttVersion = '1.2.16'

export const DB_ACCEPTOR_VERSION = 27

export const isDev = !!process.env.REACT_APP_IS_DEV

export const isProductionBuild = process.env.NODE_ENV === 'production'

export const engageAppUrl = process.env.REACT_APP_ENGAGE_APP_URL

export const helpDocUrl = (language: string, id?: string) => {
    const url = HelpDocUrlMap.get(language) ?? HelpDocUrlMap.get('en') ?? ''
    if (!id) {
        return url
    }

    return `${url}#heading=h.${HelpIdMap.get(id) ?? ''}`
}

export const routePrefix = process.env.PUBLIC_URL ? new URL(process.env.PUBLIC_URL).pathname.replace(/\/$/, '') : ''

export const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL ?? 'support@avtt.app'

export const websiteName = isDev ? 'AVTT Development' : 'Audio Video Translation Tool'

export const websiteShortName = isDev ? 'AVTT DEV' : 'AVTT'
