import { FC } from 'react'

import { observer } from 'mobx-react'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'

import MemberRoleView from './MemberRole'
import { Member, MemberRole } from '../../../models3/Member'
import { Root } from '../../../models3/Root'
import { isAllowedImage } from '../../images/AllowedImages'
import { DeleteButton } from '../../utils/Buttons'
import DropTarget from '../../utils/DropTarget'
import { DropTargetViewSmall } from '../../utils/DropTargetView'
import { displayError, systemError } from '../../utils/Errors'
import { resizeImage } from '../../utils/ImageResizer'
import { MemberDisplay } from '../../utils/MemberDisplay'

import '../ProjectSettings.css'

type MemberDropTargetProps = {
    member: Member
    rt: Root
}

const MemberDropTarget: FC<MemberDropTargetProps> = observer(({ member, rt, children }) => {
    const { t } = useTranslation()

    async function upload(files: FileList) {
        const { iAmAdmin, username, project } = rt

        if (!iAmAdmin && username !== member.email) {
            displayError(t('Unless you are an admin, you may only upload a member image for yourself.'))
            return
        }

        if (files.length !== 1) {
            displayError(t('You must drop exactly one file.'))
            return
        }

        const file = files[0]
        if (!isAllowedImage(file.type)) {
            displayError(t('Cannot upload this type of file.'))
            return
        }

        const maxFileSize = 50 * 1024 * 1024
        if (file.size > maxFileSize) {
            displayError(t('File must be smaller than 50MB.'))
            return
        }

        try {
            const resizedUrl = await resizeImage(file, 64, 64)
            await project.setMemberImage(member.email, resizedUrl)
        } catch (error) {
            systemError(error)
        }
    }

    const message = <div />
    const dropTargetView = <DropTargetViewSmall {...{ message }} />

    return <DropTarget {...{ upload, dropTargetView }}>{children}</DropTarget>
})

interface IMemberView {
    member: Member
    rt: Root
    isSoleAdmin: boolean
}

const MemberView: FC<IMemberView> = ({ member, rt, isSoleAdmin }) => {
    const { t } = useTranslation()
    const { iAmAdmin } = rt

    const onSetRole = (role: MemberRole) => {
        rt.project.setMemberRole(member.email, role).catch(displayError)
    }

    const removeMember = () => {
        confirmAlert({
            title: t('removeUser'),
            message: t('removeUserConfirm', { userEmail: member.email }),
            buttons: [
                {
                    label: t('Cancel'),
                    onClick: () => {}
                },
                {
                    label: t('removeUser'),
                    onClick: () => rt.project.removeMember(member.email).catch(displayError)
                }
            ]
        })
    }

    return (
        <tr>
            <td>
                <MemberDropTarget {...{ rt, member }}>
                    <MemberDisplay member={member} imageSize="large" showFullName />
                </MemberDropTarget>
            </td>
            <td className="vertically-centered-text">
                <MemberRoleView member={member} iAmAdmin={iAmAdmin} isSoleAdmin={isSoleAdmin} onChange={onSetRole} />
            </td>
            <td>
                {iAmAdmin && !isSoleAdmin && (
                    <DeleteButton
                        className="sl-delete-button"
                        tooltip={t('Remove this member from project')}
                        enabled
                        onClick={removeMember}
                    />
                )}
            </td>
        </tr>
    )
}

export default MemberView
