import { useEffect, useState } from 'react'

import {
    DndContext,
    closestCorners,
    KeyboardSensor,
    PointerSensor,
    useDroppable,
    useSensor,
    useSensors
} from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { GeneralQuestionEditor } from './GeneralQuestionEditor'
import { GeneralQuestionSortableItem } from './GeneralQuestionSortableItem'
import { ReviewProject } from '../../models3/ReviewProject'
import { ReviewProjectGeneralQuestion } from '../../models3/ReviewProjectGeneralQuestion'
import { move } from '../../models3/Utils'
import { AddButton } from '../utils/Buttons'
import { GenericIcon } from '../utils/Icons'

import './ProjectReview.css'

export const GeneralQuestions = observer(
    ({
        project,
        avttProjectName,
        iAmInterpreter
    }: {
        project: ReviewProject
        avttProjectName: string
        iAmInterpreter: boolean
    }) => {
        const [currentQuestion, setCurrentQuestion] = useState<ReviewProjectGeneralQuestion>()
        const { t } = useTranslation()

        const { generalQuestions } = project

        const defaultItems: { [key: string]: string[] } = {
            generalQuestionsEditor: generalQuestions.map((attribute) => attribute._id)
        }

        // I tried to derive this instead of storing it in state. It caused the dragging animations
        // to behave differently.
        const [items, setItems] = useState(defaultItems)

        const { setNodeRef } = useDroppable({ id: 'reviewProjectGeneralQuestions' })

        useEffect(() => {
            setItems({
                generalQuestionsEditor: project.generalQuestions.map((attribute) => attribute._id)
            })
        }, [project.generalQuestions])

        const sensors = useSensors(
            useSensor(PointerSensor),
            useSensor(KeyboardSensor, {
                coordinateGetter: sortableKeyboardCoordinates
            })
        )

        const findContainer = (id: string) => {
            if (id in items) {
                return id
            }

            return Object.keys(items).find((key: string) => items[key].includes(id))
        }

        const handleDragEnd = async (event: any) => {
            const { active, over } = event
            const { id } = active
            const { id: overId } = over

            const activeContainer = findContainer(id)
            const overContainer = findContainer(overId)

            if (!activeContainer || !overContainer || activeContainer !== overContainer) {
                return
            }

            const activeIndex = items[activeContainer].indexOf(id)
            const overIndex = items[overContainer].indexOf(overId)
            if (activeIndex !== overIndex) {
                const newItems = {
                    ...items,
                    [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex)
                }
                setItems(newItems)
                await move(generalQuestions, activeIndex, overIndex)
            }
        }

        return (
            <div className="profile-attributes-table">
                <GenericIcon
                    iconName="fa-comments"
                    className="default-blue-icon project-review-button icon-spacer fa-fw"
                    tooltip={t('questions')}
                />
                <div className="profile-attribute-preferences">
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCorners}
                        modifiers={[restrictToParentElement]}
                        onDragEnd={handleDragEnd}
                    >
                        <SortableContext
                            id="reviewProjectGeneralQuestions"
                            items={items.generalQuestionsEditor}
                            strategy={verticalListSortingStrategy}
                        >
                            <div
                                ref={setNodeRef}
                                className={`profile-attribute-sortable-container ${
                                    !project.generalQuestions.length
                                        ? 'profile-attribute-sortable-container-no-items'
                                        : ''
                                }`}
                            >
                                {project.generalQuestions.map((question) => (
                                    <GeneralQuestionSortableItem
                                        avttProjectName={avttProjectName}
                                        key={question._id}
                                        question={question}
                                        editItem={() => {
                                            setCurrentQuestion(question)
                                        }}
                                        iAmInterpreter={iAmInterpreter}
                                    />
                                ))}
                                <AddButton
                                    className="default-blue-icon project-review-button no-padding"
                                    enabled={iAmInterpreter}
                                    onClick={() => {
                                        const question = project.createGeneralQuestion(new Date(Date.now()))
                                        setCurrentQuestion(question)
                                    }}
                                    tooltip={t('add')}
                                />
                                {!project.generalQuestions.length && (
                                    <span className="profile-attribute-sortable-help-info">
                                        {t('addProjectEngagementQuestions')}
                                    </span>
                                )}
                            </div>
                        </SortableContext>
                    </DndContext>
                </div>
                {currentQuestion && (
                    <GeneralQuestionEditor
                        question={currentQuestion}
                        closeModal={() => {
                            setCurrentQuestion(undefined)
                        }}
                        avttProjectName={avttProjectName}
                    />
                )}
            </div>
        )
    }
)
