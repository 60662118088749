/* eslint-disable react/jsx-props-no-spreading */
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { ReviewProjectProfileAttribute } from '../../models3/ReviewProjectProfileAttribute'
import { PencilButton } from '../utils/Buttons'
import { DragAndDropIcon } from '../utils/Icons'
import { Switch } from '../utils/Switch'

export const RecordingLayoutSortableItem = observer(
    ({
        attribute,
        editItem,
        iAmInterpreter
    }: {
        attribute: ReviewProjectProfileAttribute
        editItem: () => void
        iAmInterpreter: boolean
    }) => {
        const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: attribute._id })
        const { t } = useTranslation()

        const { label, options, isActive } = attribute
        return (
            <div ref={setNodeRef} style={{ transform: CSS.Transform.toString(transform), transition }}>
                <div className="profile-attribute-sortable-container-item">
                    {iAmInterpreter && (
                        <div {...attributes} {...listeners} data-toggle="tooltip">
                            <DragAndDropIcon className="fa-lg" />
                        </div>
                    )}
                    <span className={`profile-attribute-label ${isActive ? '' : 'hidden-item'}`}>{label}</span>
                    <span className={`profile-attribute-options ${isActive ? '' : 'hidden-item'}`}>
                        <ul>
                            {options.map((option) => (
                                <li key={option.id}>{option.text}</li>
                            ))}
                        </ul>
                    </span>
                    <span className="profile-attribute-buttons">
                        <PencilButton
                            enabled={iAmInterpreter}
                            onClick={() => {
                                editItem()
                            }}
                            className="project-review-button default-blue-icon profile-attribute-edit-button"
                            tooltip={t('Edit')}
                        />
                        <Switch
                            enabled={iAmInterpreter}
                            value={isActive}
                            setValue={async (value) => {
                                await attribute.setIsActive(value)
                            }}
                            tooltip={t('showOrHide')}
                        />
                    </span>
                </div>
            </div>
        )
    }
)
