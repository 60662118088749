import { FC, useState } from 'react'

import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { PassageVideo } from '../../../models3/PassageVideo'
import LabeledFieldWithIcon from '../../utils/LabeledFieldWithIcon'
import { SimpleTextInput } from '../../utils/TextInput'
import { ModalFooter } from '../Modals'

interface Props {
    closeModal: () => void
    passageVideo: PassageVideo
    passageVideoTitles: string[]
}

const PassageTitleModal: FC<Props> = ({ closeModal, passageVideo, passageVideoTitles }) => {
    const { t } = useTranslation()
    const [errorMessage, setErrorMessage] = useState('')
    const [passageVideoTitle, setPassageVideoTitle] = useState(passageVideo.title)

    const onSave = async () => {
        await passageVideo.setTitle(passageVideoTitle)
        closeModal()
    }

    const handleValueValidation = (value: string) => {
        if (passageVideoTitles.filter((title) => title !== passageVideo.title).includes(value.trim())) {
            return setErrorMessage(t('Duplicate name'))
        }

        const versionRegex = new RegExp(`^(?:Version|${t('Version')}) [0-9]+$`, 'i')
        if (versionRegex.test(value.trim())) {
            return setErrorMessage(t('invalidTitleFormat'))
        }

        return setErrorMessage('')
    }

    const handleSetValue = (value: string) => {
        setPassageVideoTitle(value)
        handleValueValidation(value)
    }

    return (
        <Modal backdrop="static" onHide={closeModal} show>
            <Modal.Header closeButton>
                <Modal.Title>{t('editRecordingVersion')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LabeledFieldWithIcon
                    iconName="fa-tag"
                    title={t('Title')}
                    field={
                        <SimpleTextInput
                            value={passageVideoTitle}
                            setValue={handleSetValue}
                            errorMessage={errorMessage}
                            autoFocus
                        />
                    }
                />
            </Modal.Body>
            <ModalFooter
                enabledOK={Boolean(!errorMessage.length)}
                enabledCancel
                onOK={onSave}
                onCancel={closeModal}
                okTooltip={t('OK')}
                cancelTooltip={t('Cancel')}
            />
        </Modal>
    )
}

export default PassageTitleModal
