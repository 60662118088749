import { FC } from 'react'

import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { Member, MemberRole } from '../../../models3/Member'

interface MemberRoleViewProps {
    member: Member
    iAmAdmin: boolean
    isSoleAdmin: boolean
    onChange: (role: MemberRole) => void
}

const roleDescriptions = (t: TFunction) => ({
    admin: { label: t('roleAdmin'), title: t('roleAdminDescription') },
    translator: { label: t('roleTranslator'), title: t('roleTranslatorDescription') },
    consultant: { label: t('roleConsultant'), title: t('roleConsultantDescription') },
    interpreter: { label: t('roleInterpreter'), title: t('roleInterpreterDescription') },
    backTranslator: { label: t('roleBackTranslator'), title: t('roleBackTranslatorDescription') },
    observer: { label: t('roleObserver'), title: t('roleObserverDescription') }
})

const MemberRoleView: FC<MemberRoleViewProps> = ({ iAmAdmin, isSoleAdmin, member, onChange }) => {
    const { t } = useTranslation()
    const { role } = member

    const roles = roleDescriptions(t)
    const displayedRole = roles[role].label

    if (iAmAdmin && !isSoleAdmin) {
        return (
            <select value={role} onChange={(e) => onChange(e.target.value as MemberRole)}>
                {Object.entries(roles).map(([value, { label, title }]) => (
                    <option key={value} value={value} title={title}>
                        {label}
                    </option>
                ))}
            </select>
        )
    }

    return <span>{displayedRole}</span>
}

export default MemberRoleView
