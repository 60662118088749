import { useEffect, useState } from 'react'

import { MarkerParams } from 'wavesurfer.js/src/plugin/markers'

import { createBiblicalTermMarker, createVerseReferenceMarker } from './WaveSurferAudioPlayerCore'
import { WaveSurferAudioPlayerLite } from './WaveSurferAudioPlayerLite'
import { Passage } from '../../models3/Passage'
import { PassageVideo } from '../../models3/PassageVideo'
import { getVisiblePassageOrPortionRefRanges } from '../../models3/ProjectReferences'
import { Root } from '../../models3/Root'
import { LoadingIcon } from '../utils/Icons'
import { createPassageAudioBuffer } from '../utils/Wav'

interface WaveSurferPassageAudioPlayerProps {
    rt: Root
    passage: Passage
    passageVideo: PassageVideo
    termId: string
}

export const WaveSurferPassageAudioPlayer = ({
    rt,
    passage,
    passageVideo,
    termId
}: WaveSurferPassageAudioPlayerProps) => {
    const [audioUrl, setAudioUrl] = useState<string | undefined>(undefined)

    useEffect(() => {
        let wavSrc = ''
        let effectCancelled = false

        const createBuffer = async () => {
            wavSrc = await createPassageAudioBuffer(passage, passageVideo)
            if (!wavSrc) {
                return
            }

            if (effectCancelled) {
                URL.revokeObjectURL(wavSrc)
                return
            }

            setAudioUrl(wavSrc)
        }

        createBuffer()

        return () => {
            effectCancelled = true
            URL.revokeObjectURL(wavSrc)
        }
    }, [passage, passageVideo])

    if (!audioUrl) {
        return <LoadingIcon className="" />
    }

    const verseMarkerParams: MarkerParams[] = passageVideo.getVisibleReferenceMarkers(passage).map((marker) => {
        const markerElement = createVerseReferenceMarker({ marker, rt })
        return { position: 'top', time: marker.time, markerElement }
    })

    const refRanges = getVisiblePassageOrPortionRefRanges({ passage, portion: rt.portion })
    const terms = rt.project.getKeyTermsThatOccurInVerses(refRanges)
    const glossIdPrefix = terms.find((term) => term.lexicalLink === termId)?._id ?? ''
    let startTime = 0
    const termMarkerParams: MarkerParams[] = passageVideo.getVisibleBiblicalTermMarkers(passage).map((marker) => {
        const time = marker.time
        const markerElement = createBiblicalTermMarker({ marker, terms })
        let label = ''
        if (glossIdPrefix && marker.targetGlossId.startsWith(glossIdPrefix)) {
            label = markerElement.title // only display gloss for the related lemma term
            startTime = startTime === 0 ? time : Math.min(time, startTime) // set startTime to the earliest marker time
        }
        return { position: 'top', time, label, markerElement }
    })
    startTime = startTime < 1 ? 0 : startTime - 1 // play a second before the marker

    return (
        <WaveSurferAudioPlayerLite
            url={audioUrl}
            markerParams={verseMarkerParams.concat(termMarkerParams)}
            startTime={startTime}
        />
    )
}
