import { useCallback, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import { CacheTypeChooser } from './CacheTypeChooser'
import { MediaTypeChooser } from './MediaTypeChooser'
import { ResultsPage } from './ResultsPage'
import { Root } from '../../../models3/Root'
import { audioBookExists, bookExists } from '../../../resources/publishedBibles'
import { CacheType, CachingProgress, MediaType, PublishedBible, MimeType } from '../../../types'
import { useExegeticalResources } from '../../app/ExegeticalResourcesContext'
import { useOnlineStatus } from '../../app/OnlineStatusContext'
import { usePublishedBibles } from '../../app/PublishedBiblesContext'
import { supportEmail } from '../../app/slttAvtt'
import { useTranslationResourceCaching } from '../../app/TranslationResourceCachingContext'
import { ERResourceSelector } from '../../enhancedResources/EnhancedResourceViewer'
import FilePicker from '../../filePickers/FilePicker'
import { ItemRendererProps, MultiSelect, Option } from '../../projectSettings/projectResources/MultiSelect'
import {
    ResourceImportError,
    importResources,
    isResourceExportFileName
} from '../../projectSettings/projectResources/ResourceImporter'
import { CancelButton, OKButton } from '../../utils/Buttons'
import { ErrorList } from '../../utils/ErrorList'
import { UpdateAvailableIcon, GenericIcon } from '../../utils/Icons'
import { Switch } from '../../utils/Switch'
import { WarningList } from '../../utils/WarningList'
import { ProgressModalBodyAndFooter } from '../Modals'

interface CacheMediaType {
    cacheType: CacheType
    mediaTypes: MediaType[]
}

interface DownloadModalProps {
    rt: Root
    closeModal: () => void
}

interface ValidateFile {
    id: number
    file: File
    isValid: boolean
}

const UPDATE_AVAILABLE_INDICATOR = '\u200B' // Zero-width space, so selected text will not add something visible to the book name selection

const BookItemRenderer = ({ checked, option, onClick, disabled }: ItemRendererProps<number>) => {
    const { label } = option
    const bookHasUpdate = label.endsWith(UPDATE_AVAILABLE_INDICATOR)

    return (
        <div className={`item-renderer ${disabled ? 'disabled' : ''}`}>
            <input type="checkbox" onChange={onClick} checked={checked} tabIndex={-1} disabled={disabled} />
            <span>
                {label} {bookHasUpdate && <UpdateAvailableIcon />}
            </span>
        </div>
    )
}

export const DownloadModal = observer(({ rt, closeModal }: DownloadModalProps) => {
    const {
        t,
        i18n: { language }
    } = useTranslation()
    const { visibleBibles } = usePublishedBibles()

    const { iAmRoot, project } = rt

    const [exportResources, setExportResources] = useState(false)
    const [cacheType, setCacheType] = useState(CacheType.BIBLES)
    const [mediaType, setMediaType] = useState<MediaType | undefined>(MediaType.AUDIO)
    const [bibleVersion, setBibleVersion] = useState<PublishedBible>()
    const [selectedOptions, setSelectedOptions] = useState<Option<number>[]>([])
    const [showResultsPage, setShowResultsPage] = useState(false)
    const [downloadType, setDownloadType] = useState<number>(0)
    const [files, setFiles] = useState<ValidateFile[]>()
    const [showProgressPage, setShowProgressPage] = useState(false)
    const [triedToDownload, setTriedToDownload] = useState(false)
    const [archivedError, setArchivedError] = useState('')
    const [isImportInProgress, setIsImportInProgress] = useState(false)
    const { requestResources, getProgress, exportProgress } = useTranslationResourceCaching()
    const { doesExegeticalResourceBookExist, exegeticalResourceMediaTypes } = useExegeticalResources()
    const { isOnline } = useOnlineStatus()

    const activeTab = downloadType === 0 ? 'internet' : 'archived'

    // Keep the current tab open, but reset all other state
    const resetAllState = () => {
        setShowProgressPage(false)
        setShowResultsPage(false)
        setCacheType(CacheType.BIBLES)
        setMediaType(MediaType.AUDIO)
        setExportResources(false)
        setBibleVersion(undefined)
        setIsImportInProgress(false)
        setArchivedError('')
        setSelectedOptions([])
        setFiles([])
    }

    const handleError = (err: Error) => {
        if (err.message === ResourceImportError.FileValidationFailed) {
            setArchivedError(t('importResourcesValidationError'))
        } else if (err.message === ResourceImportError.GenericFailure) {
            setArchivedError(t('importResourcesGenericError'))
        } else {
            setArchivedError(t('An error occurred.'))
        }
    }

    const uploadResourcesFiles = async () => {
        if (!files || files.length === 0) {
            return
        }

        setTriedToDownload(true)
        setShowProgressPage(true)
        setIsImportInProgress(true)

        for (const currentFile of files) {
            try {
                await importResources(currentFile.file)
            } catch (err) {
                handleError(err as Error)
            }
        }

        setIsImportInProgress(false)
    }

    const chooseFiles = (zipFiles: FileList) => {
        setArchivedError('')

        const validatedFiles: ValidateFile[] = Array.from(zipFiles).map((file, id) => {
            const isValid = isResourceExportFileName(file.name)
            if (!isValid) {
                handleError(new Error(ResourceImportError.FileValidationFailed))
            }
            return { id, file, isValid }
        })

        setFiles(validatedFiles)
    }

    const setCacheRequestType = (requestType: CacheType) => {
        if (requestType === CacheType.IMAGES) {
            setMediaType(undefined)
        } else if (!mediaType) {
            setMediaType(MediaType.AUDIO)
        }
        setCacheType(requestType)
        setBibleVersion(undefined)
        setSelectedOptions([])
    }

    const setRequestMediaType = (requestType: MediaType) => {
        setMediaType(requestType)
        setBibleVersion(undefined)
        setSelectedOptions([])
    }

    const setBible = (bible: PublishedBible) => {
        setBibleVersion(bible)
        setSelectedOptions([])
    }

    const [availableBooks, multiSelectOptions] = useMemo(() => {
        const bookOptions: Option<number>[] = []
        const existingBooks: { bookId: string; bookName: string; bookNumber: number }[] = []

        // Pre-compute conditions that are invariant inside the loop
        const isBiblesCacheType = cacheType === CacheType.BIBLES && bibleVersion
        const isExegeticalResourcesCacheType = cacheType === CacheType.EXEGETICAL_RESOURCES && mediaType

        Object.entries(project.bookNames).forEach(([bookId, bookName]) => {
            const bookNumber = Number(bookId)
            let includeBook = false

            if (cacheType === CacheType.IMAGES) {
                includeBook = true
            } else if (isExegeticalResourcesCacheType) {
                includeBook = doesExegeticalResourceBookExist(bookId, mediaType)
            } else if (isBiblesCacheType) {
                includeBook =
                    mediaType === MediaType.AUDIO
                        ? audioBookExists(bookNumber, bibleVersion)
                        : bookExists(bookNumber, bibleVersion)
            }

            if (includeBook) {
                const progress = getProgress({ bookNumber, bibleVersion, cacheType, mediaType, language })
                const disabled = progress === CachingProgress.CACHED
                const label =
                    progress === CachingProgress.UPDATE_AVAILABLE
                        ? `${bookName}${UPDATE_AVAILABLE_INDICATOR}`
                        : bookName
                bookOptions.push({ value: bookNumber, label, disabled })
                existingBooks.push({ bookId, bookName, bookNumber })
            }
        })

        return [existingBooks, bookOptions]
    }, [bibleVersion, cacheType, mediaType, language, project.bookNames, doesExegeticalResourceBookExist, getProgress])

    const showBooksSelector = Boolean(
        !exportResources &&
            (cacheType === CacheType.IMAGES ||
                cacheType === CacheType.EXEGETICAL_RESOURCES ||
                (cacheType === CacheType.BIBLES && bibleVersion))
    )

    const cacheMediaTypes = useMemo(() => {
        const types: CacheMediaType[] = []

        if (visibleBibles.length) {
            const mediaTypes: MediaType[] = []
            if (visibleBibles.some((bible) => bible.hasAudio)) {
                mediaTypes.push(MediaType.AUDIO)
            }
            mediaTypes.push(MediaType.TEXT)
            types.push({ cacheType: CacheType.BIBLES, mediaTypes })
        }

        if (exegeticalResourceMediaTypes.length) {
            types.push({
                cacheType: CacheType.EXEGETICAL_RESOURCES,
                mediaTypes: exegeticalResourceMediaTypes as MediaType[]
            })
        }

        types.push({ cacheType: CacheType.IMAGES, mediaTypes: [] })

        return types
    }, [visibleBibles, exegeticalResourceMediaTypes])

    const includePublishedBible = useCallback(
        (bible: PublishedBible) => mediaType === MediaType.TEXT || bible.hasAudio,
        [mediaType]
    )

    const cacheTypes = cacheMediaTypes.map((cachedMediaType) => cachedMediaType.cacheType)
    const cachedMediaType = cacheMediaTypes.find((cacheMediaType) => cacheMediaType.cacheType === cacheType)
    const mediaTypes = cachedMediaType?.mediaTypes ?? []
    const bookNumbers = exportResources
        ? availableBooks.map(({ bookNumber }) => bookNumber)
        : selectedOptions.map((option) => option.value)

    const handleOKClick = () => {
        if (bookNumbers.length > 0 && activeTab === 'internet') {
            requestResources({
                bookNumbers,
                bibleVersion,
                cacheType,
                language,
                mediaType,
                exportResources
            })
            setTriedToDownload(true)
            setShowResultsPage(true)
            return
        }

        if (files && files.length > 0 && !archivedError && activeTab === 'archived') {
            uploadResourcesFiles()
        }
    }

    const closeOrReload = () => {
        if (triedToDownload || showResultsPage || showProgressPage) {
            location.reload()
        } else {
            closeModal()
        }
    }

    return (
        <Modal
            onHide={closeOrReload}
            show
            backdrop="static"
            onEscapeKeyDown={(event: KeyboardEvent) => {
                event.preventDefault()
                closeOrReload()
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {showResultsPage || showProgressPage ? t('downloadingToDevice') : t('downloadToDevice')}
                </Modal.Title>
            </Modal.Header>
            {showResultsPage && (
                <ResultsPage
                    cacheType={cacheType}
                    mediaType={mediaType}
                    selectedOptions={exportResources ? multiSelectOptions : selectedOptions}
                    closeModal={closeOrReload}
                    publishedBible={bibleVersion}
                    exportResources={exportResources}
                    exportProgress={exportProgress}
                    resetModal={resetAllState}
                />
            )}
            {showProgressPage && (
                <ProgressModalBodyAndFooter
                    closeModal={closeOrReload} // Reload so changes can be reflected in UI
                    error={archivedError}
                    loading={isImportInProgress}
                    loadingMessage={t('importingResources')}
                    successBody={<div>{t('importResourcesSuccess')}</div>}
                    resetModal={resetAllState}
                />
            )}
            {!showResultsPage && !showProgressPage && (
                <>
                    <Modal.Body>
                        <Tabs selectedIndex={downloadType} onSelect={(index: number) => setDownloadType(index)}>
                            <TabList>
                                <Tab>
                                    <GenericIcon
                                        iconName="fa-wifi"
                                        className="modal-back-translation-icon"
                                        tooltip={t('downloadFromInternet')}
                                    />
                                </Tab>
                                <Tab>
                                    <GenericIcon
                                        iconName="fa-file-arrow-down"
                                        className="modal-back-translation-icon"
                                        tooltip={t('downloadFromArchivedFiles')}
                                    />
                                </Tab>
                            </TabList>
                            <TabPanel>
                                {!isOnline && (
                                    <div className="modal-error-message">
                                        <ErrorList errors={[{ text: t('noInternetDownloadTab'), key: '' }]} />
                                    </div>
                                )}
                                {iAmRoot && (
                                    <div className="modal-toggle">
                                        {t('exportResourcesForImporting')}
                                        <Switch
                                            value={exportResources}
                                            setValue={(newValue) => {
                                                setExportResources(newValue)
                                                if (newValue) {
                                                    setSelectedOptions(multiSelectOptions)
                                                }
                                            }}
                                            enabled={isOnline}
                                            tooltip={exportResources ? t('exportResources') : t('downloadToDevice')}
                                        />
                                    </div>
                                )}
                                <CacheTypeChooser
                                    cacheTypes={cacheTypes}
                                    selected={cacheType}
                                    setSelected={setCacheRequestType}
                                    enabled={isOnline}
                                />
                                {mediaTypes.length > 0 && mediaType && (
                                    <MediaTypeChooser
                                        mediaTypes={mediaTypes}
                                        selected={mediaType}
                                        setSelected={setRequestMediaType}
                                        enabled={isOnline}
                                    />
                                )}
                                {cacheType === CacheType.BIBLES && (
                                    <>
                                        <div className="modal-subheading">{t('bibleVersion')}</div>
                                        <div className="modal-dropdown">
                                            <ERResourceSelector
                                                setBible={setBible}
                                                visibleBibles={visibleBibles.filter(includePublishedBible)}
                                                currentBible={bibleVersion}
                                                idSuffix="download-resources-modal"
                                                enabled={isOnline}
                                            />
                                        </div>
                                    </>
                                )}
                                {showBooksSelector && (
                                    <>
                                        <div className="modal-subheading">{t('books')}</div>
                                        <MultiSelect
                                            options={multiSelectOptions}
                                            selected={selectedOptions}
                                            setSelected={setSelectedOptions}
                                            ItemRenderer={BookItemRenderer}
                                        />
                                    </>
                                )}
                            </TabPanel>
                            <TabPanel>
                                <p>{t('importResourcesHelp', { supportEmail })}</p>
                                <div className="modal-file-picker">
                                    <FilePicker
                                        className="modal-file-picker-icon"
                                        enabled
                                        accept={MimeType.ZIP}
                                        setSelectedFiles={chooseFiles}
                                        multiple
                                    />
                                    {t('chooseFiles')}
                                </div>
                                {files && (
                                    <ul className="modal-file-list">
                                        {files.map((singleFile) =>
                                            singleFile.isValid ? (
                                                <li key={singleFile.id}>{singleFile.file.name}</li>
                                            ) : (
                                                <li key={singleFile.id}>
                                                    {singleFile.file.name}
                                                    <GenericIcon
                                                        iconName="fa-exclamation-triangle"
                                                        className="warning-list-warning-icon"
                                                        tooltip={archivedError}
                                                    />
                                                </li>
                                            )
                                        )}
                                    </ul>
                                )}
                                {archivedError && (
                                    <WarningList warnings={[{ text: archivedError, key: archivedError }]} />
                                )}
                            </TabPanel>
                        </Tabs>
                    </Modal.Body>
                    <Modal.Footer className="flex-centered">
                        <div className="button-row">
                            <OKButton
                                enabled={
                                    (isOnline && bookNumbers.length > 0 && activeTab === 'internet') ||
                                    (Boolean(files && files.length > 0) && !archivedError && activeTab === 'archived')
                                }
                                onClick={handleOKClick}
                                className="ok-button"
                                tooltip={t('OK')}
                            />
                            <CancelButton
                                enabled
                                onClick={closeOrReload}
                                className="cancel-button"
                                tooltip={t('Cancel')}
                            />
                        </div>
                    </Modal.Footer>
                </>
            )}
        </Modal>
    )
})
