import { PropsWithChildren, ReactNode, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { LocalStorageKeys } from '../app/slttAvtt'

import './HorizontalAccordion.css'

interface AccordionItemProps {
    title: string
    isOpen: boolean
    onClick: () => void
    disabled?: boolean
    maxWidth?: string
}

const AccordionItem = ({
    title,
    isOpen,
    disabled,
    onClick,
    maxWidth,
    children
}: PropsWithChildren<AccordionItemProps>) => {
    const { t } = useTranslation()
    const tooltip = !disabled
        ? isOpen
            ? t('horizontalAccordionTooltipCollapse')
            : t('horizontalAccordionToolipExpand')
        : ''

    return (
        <div className={`accordion-card ${isOpen ? '' : 'closed'}`} style={{ maxWidth }}>
            <div className="accordion-card-header" aria-disabled={disabled} onClick={onClick} title={tooltip}>
                <h3>{title}</h3>
            </div>
            <div className="accordion-card-content width">{children}</div>
        </div>
    )
}

type AccordionItemVariant = 'left' | 'middle' | 'right'

type AccordionItems = {
    title: string
    content: ReactNode
}

type HorizontalAccordionProps = {
    left: AccordionItems
    middle: AccordionItems
    right: AccordionItems
    maxWidthItem: {
        item: AccordionItemVariant
        width: string
    }
}

const initializeOpenState = (key: string, content: ReactNode) =>
    Boolean(content) && [null, 'true'].includes(localStorage.getItem(key))

const HorizontalAccordion = ({ left, middle, right, maxWidthItem }: HorizontalAccordionProps) => {
    const [isLeftOpen, setIsLeftOpen] = useState(() =>
        initializeOpenState(LocalStorageKeys.ACCORDION_LEFT_IS_OPEN, left.content)
    )
    const [isMiddleOpen, setIsMiddleOpen] = useState(() =>
        initializeOpenState(LocalStorageKeys.ACCORDION_MIDDLE_IS_OPEN, middle.content)
    )
    const [isRightOpen, setIsRightOpen] = useState(() =>
        initializeOpenState(LocalStorageKeys.ACCORDION_RIGHT_IS_OPEN, right.content)
    )

    const handleToggle = (
        setState: React.Dispatch<React.SetStateAction<boolean>>,
        key: string,
        currentState: boolean
    ) => {
        const newState = !currentState
        setState(newState)
        localStorage.setItem(key, newState.toString())
    }

    const accordionItems = [
        {
            key: LocalStorageKeys.ACCORDION_LEFT_IS_OPEN,
            isOpen: isLeftOpen,
            setIsOpen: setIsLeftOpen,
            data: left,
            disabled: isLeftOpen && !isRightOpen && !isMiddleOpen,
            maxWidth: (isMiddleOpen || isRightOpen) && maxWidthItem?.item === 'left' ? maxWidthItem.width : '100%'
        },
        {
            key: LocalStorageKeys.ACCORDION_MIDDLE_IS_OPEN,
            isOpen: isMiddleOpen,
            setIsOpen: setIsMiddleOpen,
            data: middle,
            disabled: isMiddleOpen && !isLeftOpen && !isRightOpen,
            maxWidth: (isLeftOpen || isRightOpen) && maxWidthItem?.item === 'middle' ? maxWidthItem.width : '100%'
        },
        {
            key: LocalStorageKeys.ACCORDION_RIGHT_IS_OPEN,
            isOpen: isRightOpen,
            setIsOpen: setIsRightOpen,
            data: right,
            disabled: isRightOpen && !isLeftOpen && !isMiddleOpen,
            maxWidth: (isLeftOpen || isMiddleOpen) && maxWidthItem?.item === 'right' ? maxWidthItem.width : '100%'
        }
    ].filter(({ data: { content } }) => Boolean(content))

    return (
        <div className="accordion width">
            {accordionItems.map(({ key, isOpen, setIsOpen, data: { title, content }, disabled, maxWidth }) => (
                <AccordionItem
                    key={key}
                    title={title}
                    isOpen={isOpen}
                    onClick={() => handleToggle(setIsOpen, key, isOpen)}
                    disabled={disabled}
                    maxWidth={maxWidth}
                >
                    {content}
                </AccordionItem>
            ))}
        </div>
    )
}

export default HorizontalAccordion
